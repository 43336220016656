import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MSAL_INSTANCE, MsalModule, MsalService } from '@azure/msal-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MSALFactory } from './msalFactory';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, MsalModule],
  providers: [{ provide: MSAL_INSTANCE, useFactory: MSALFactory }, MsalService],
  bootstrap: [AppComponent],
})
export class AppModule {}
