<div>
  <button (click)="login()">Login</button>
  <button (click)="getUserDetails()">MS graph user details</button>
  <button (click)="getUserProfilePic()">MS graph user profile pic</button>
  <button (click)="callUnProtectedAPI()">Unprotected API call</button>
  <button (click)="callProtectedAPI()">Call protected API</button>
  <button (click)="callProtectedAPI2()">Call protected API 2</button>
</div>

<div>
  {{ userDetails | json }}
</div>
<div>
  <img [src]="userImgUrl">
</div>
