import { BrowserCacheLocation, PublicClientApplication } from '@azure/msal-browser';

export function MSALFactory() {
  return new PublicClientApplication({
    auth: {
      clientId: '23a7b1c1-c8b4-4405-a0e0-797cc6c88650',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
    system: {},
  });
}
