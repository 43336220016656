import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private readonly msalService: MsalService,
    private readonly domSanitizer: DomSanitizer,
    private readonly http: HttpClient
  ) {}

  public userDetails: any;
  public userImgUrl: SafeUrl;

  public login(): void {
    const request = {
      scopes: ['api://a2563a71-7731-42ac-a853-1bbc70b2bc80/.default'
      ]
    };
    this.msalService.loginPopup(request).subscribe();
  }

  public acquireToken(scopes: string[]): Observable<AuthenticationResult> {
    const accountInfo =
      this.msalService.instance.getActiveAccount() ??
      this.msalService.instance.getAllAccounts()[0];

    return this.msalService
      .acquireTokenSilent({
        account: accountInfo,
        scopes: scopes,
      })
      .pipe(
        catchError(() => {
          return this.msalService.acquireTokenPopup({ scopes });
        })
      );
  }

  public getUserDetails(): void {
    const graphMeEndpoint = 'https://graph.microsoft.com/v1.0/me';
    const scopes = ['user.read'];

    this.acquireToken(scopes)
      .pipe(
        switchMap((value) => {
          console.log(value);

          const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${value.accessToken}`
          );
          return this.http.get<any>(graphMeEndpoint, { headers });
        })
      )
      .subscribe((res) => {
        this.userDetails = res;
      });
  }

  public getUserProfilePic(): void {
    const graphMePhotoEndpoint =
      'https://graph.microsoft.com/v1.0/me/photo/$value';
    const scopes = ['user.read'];

    this.acquireToken(scopes)
      .pipe(
        switchMap((value) => {
          console.log(value);

          const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${value.accessToken}`
          );
          return this.http.get(graphMePhotoEndpoint, {
            headers: headers,
            responseType: 'blob',
          });
        })
      )
      .subscribe((res) => {
        this.userImgUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
          window.URL.createObjectURL(res)
        );
      });
  }

  public callUnProtectedAPI(): void {
    this.http.get<any>('https://ms-identity-test.purpletuesday.net/api/test/anon').subscribe();
  }

  public callProtectedAPI(): void {
    const scopes = [
      'api://a2563a71-7731-42ac-a853-1bbc70b2bc80/access_as_user',
    ];
    this.acquireToken(scopes)
      .pipe(
        switchMap((value) => {
          console.log(value);

          const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${value.accessToken}`
          );
          return this.http.get('https://ms-identity-test.purpletuesday.net/api/test/auth', { headers });
        })
      )
      .subscribe((res) => {
        console.log(res);
      });
  }

  public callProtectedAPI2(): void {
    const scopes = [
      'api://a2563a71-7731-42ac-a853-1bbc70b2bc80/access_as_user',
    ];
    this.acquireToken(scopes)
      .pipe(
        switchMap((value) => {

          const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${value.accessToken}`
          );
          return this.http.get(`https://ms-identity-test.purpletuesday.net/api/test/User-On-Behalf`, { headers });
        })
      )
      .subscribe((res) => {
        console.log(res);
      });
  }  
}
